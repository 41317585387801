import { gql } from '@apollo/client';
import { EXTRA_INFO } from './extra-fragments';

export const SPOT_BASE_INFO = gql`
    fragment SpotBaseInfo on Spot {
        id
        title
        rating
        street
        country
        state
        city
        zip
        live
        spotUrl
    }
`;

export const SPOT_SHORT_INFO = gql`
    fragment SpotShortInfo on Spot {
        ...SpotBaseInfo
        allPhotos
    }
    ${SPOT_BASE_INFO}
`;

export const SPOT_LISTINGS_INFO_SEARCH = gql`
    fragment SpotListingsInfoSearch on Spot {
        id
        title
        rating
        latitude
        longitude
        distanceFrom
        live
        allPhotos
        size
        sizeUnits
        price
        reviewsCount
        enclosureType
        fenceHeight
        top
        state
        city
    }
`;

export const SPOT_LISTINGS_INFO = gql`
    fragment SpotListingsInfo on Spot {
        id
        title
        rating
        latitude
        longitude
        distanceFrom
        allAmenities(limit: 4) {
            component
            name
        }
        topReview {
            id
            comment
        }
        live
        allPhotos
        size
        sizeUnits
        filtered
        price
        offerMembership
        offerSniffpass
        activeMembership
        reviewsCount
        enclosureType
        fenceHeight
        privateEntry
        dogsPresent
        domesticAnimalsPresent
        description
        peoplePresent
        top
        new
        risingStar
        spotUrl
        country
        state
        city
        host {
            id
            nameAndInitial
        }

        publicPark
        workHours
        fee
    }
`;

export const SPOT_FAQS_INFO = gql`
    fragment SpotFaqsInfo on Spot {
        id
        title
        price
        spotUrl
    }
`;

export const SPOT_DETAIL_INFO_SHORT = gql`
    fragment SpotDetailInfoShort on Spot {
        id
        title
        rating
        latitude
        longitude
        distanceFrom
        live
        thumbPhoto
        size
        sizeUnits
        price
        reviewsCount
        enclosureType
        fenceHeight
        privateEntry
        dogsPresent
        domesticAnimalsPresent
        peoplePresent
        top
        street
        city
        state
        country
        notReviewed
        host {
            id
        }
    }
`;

export const NEARBY_SPOT_INFO = gql`
    fragment NearbySpotInfo on Spot {
        id
        title
        description
        distanceFrom
        rating
        live
        allPhotos
        size
        sizeUnits
        price
        reviewsCount
        enclosureType
        fenceHeight
        top
        new
        risingStar
        country
        state
        city
        spotUrl
    }
`;

export const SPOT_VERY_SHORT_INFO = gql`
    fragment SpotVeryShortInfo on Spot {
        ...SpotBaseInfo
        allPhotos
    }
    ${SPOT_BASE_INFO}
`;

export const SPOT_LIVE_FRAGMENT = gql`
    fragment LiveFragment on Spot {
        live
        turnBackOnAt
        turnOffReason
    }
`;

export const SPOT_INFO = gql`
    fragment SpotInfo on Spot {
        ...SpotShortInfo
        size
        sizeUnits
        price
        rating
        reviewsCount
        distanceFrom
        guestNumber
        repeatScore
        enclosureType
        dogsPresent
        domesticAnimalsPresent
        peoplePresent
        isPrivate
        privateEntry
        describePrivateEntry
        fenceHeight
        instructions
        description
        key
        publishedAt
        promoDiscount {
            id
            code
        }
        dogsAmenities {
            name
        }
        essentialAmenities {
            name
        }
        peopleAmenities {
            name
        }
        venueAmenities {
            name
        }
        top
        memberPrice2Hour
        memberPrice4Hour
        memberPrice8Hour
        memberHostBenefits
        offerMembership
        activeMembership
        offerSniffpass
        lastReservationAt
        availabilityConfirmedAt
    }
    ${SPOT_SHORT_INFO}
`;

const SPOT_AMENITIES = gql`
    fragment SpotAmenities on Spot {
        dogsAmenities {
            component
            icon
            name
            spotOrder
            hostOrder
        }
        essentialAmenities {
            component
            icon
            name
            spotOrder
            hostOrder
        }
        peopleAmenities {
            component
            icon
            name
            spotOrder
            hostOrder
        }
        venueAmenities {
            component
            icon
            name
            spotOrder
            hostOrder
        }
    }
`;

export const SPOT_CONFIRM_PERCENTAGE = gql`
    fragment SpotConfirmPercentage on Spot {
        cleanlinessPercentage
        dogsPercentage
        donatePercent
        fencingPercentage
        otherAnimalsPercentage
        peoplePercentage
    }
`;

export const SPOT_GUEST_MEMBERSHIPS = gql`
    fragment SpotGuestMemberships on Spot {
        id
        title
        thumbPhoto
        maximumDogsAllowed
        memberPrice2Hour
        memberPrice4Hour
        memberPrice8Hour
    }
`;

export const SPOT_FULL = gql`
    fragment SpotFull on Spot {
        ...LiveFragment
        ...SpotInfo
        ...SpotAmenities
        ...SpotConfirmPercentage
        id
        photos {
            id
            imageAws
            ordinal
            caption
        }
        accessPhotos {
            id
            imageAws
            ordinal
            caption
        }
        credits {
            available
            inviteTotal
            total
            membershipped
        }
        availabilityWindow
        minimumLength
        maximumLength
        manualPrice
        recommendedPrice
        autoPriceOn
        autoPriceMin
        autoPriceMax
        title
        latitude
        longitude
        distanceFrom
        lastReservationAt
        openNow
        describeDogsPresent
        describeDomesticAnimalsPresent
        describePeoplePresent
        fencingType
        fencingDetail
        street
        country
        zip
        state
        city
        top
        new
        risingStar
        gaps
        rules
        hasBookedBefore
        maximumDogsAllowed
        hostPresencePreference
        reservationDelayTime
        breedSizeRestrictions
        createdPlatform
        publishedPlatform
        inactiveWarning
        donateTo
        donatePercent
        hazards
        timezone
        firstTime
        firstTimeGuest
        dogsAway
        peopleAway
        spotUrl
        cityUrl
        stateUrl
        stateShort
        extras {
            ...ExtraInfo
        }
        contacts {
            id
            contact
        }
        host {
            id
            firstname
            nameAndInitial
            avatarAws
            aboutHost
            createdAt
        }
        spotPhotos {
            id
            createdAt
            imageAws
            caption
            providedBy {
                id
                firstname
                lastInitial
            }
            providedById
        }
        acceptanceRate
        responseTime
        isMember
        reservationReminders
        nearbySpots {
            ...NearbySpotInfo
        }
    }
    ${NEARBY_SPOT_INFO}
    ${SPOT_LIVE_FRAGMENT}
    ${SPOT_INFO}
    ${SPOT_AMENITIES}
    ${SPOT_CONFIRM_PERCENTAGE}
    ${EXTRA_INFO}
`;

export const SPOT_FULL_LISTING = gql`
    fragment SpotFullListing on Spot {
        acceptanceRate
        activeMembership
        availabilityConfirmedAt
        availabilityWindow
        autoPriceMax
        autoPriceMin
        autoPriceOn
        breedSizeRestrictions
        city
        cityUrl
        cleanlinessPercentage
        country
        createdPlatform
        describeDogsPresent
        describeDomesticAnimalsPresent
        describePeoplePresent
        describePrivateEntry
        description
        distanceFrom
        dogsAway
        dogsPercentage
        dogsPresent
        donatePercent
        domesticAnimalsPresent
        enclosureType
        extras {
            id
            title
            price
            description
        }
        fenceHeight
        fencingDetail
        fencingPercentage
        fencingType
        firstTime
        firstTimeGuest
        guestNumber
        hasBookedBefore
        hazards
        host {
            id
            firstname
            nameAndInitial
            avatarAws
            aboutHost
            createdAt
        }
        hostPresencePreference
        id
        inactiveWarning
        instructions
        isMember
        isPrivate
        latitude
        live
        longitude
        manualPrice
        maximumDogsAllowed
        maximumLength
        memberHostBenefits
        memberPrice2Hour
        memberPrice4Hour
        memberPrice8Hour
        minimumLength
        nearbySpots {
            id
            title
            description
            distanceFrom
            rating
            live
            allPhotos
            size
            sizeUnits
            price
            reviewsCount
            enclosureType
            fenceHeight
            top
            new
            risingStar
            country
            state
            city
            spotUrl
        }
        new
        offerMembership
        offerSniffpass
        openNow
        otherAnimalsPercentage
        peopleAway
        peoplePercentage
        peoplePresent
        price
        privateEntry
        publishedAt
        publishedPlatform
        rating
        recommendedPrice
        reservationDelayTime
        reservationReminders
        responseTime
        reviewsCount
        risingStar
        rules
        size
        sizeUnits
        spotPhotos {
            id
            createdAt
            imageAws
            caption
            providedBy {
                id
                firstname
                lastInitial
            }
            providedById
        }
        spotUrl
        state
        stateShort
        stateUrl
        street
        timezone
        title
        top
        turnBackOnAt
        turnOffReason
        zip
        dogsAmenities {
            component
            icon
            name
            spotOrder
            hostOrder
        }
        essentialAmenities {
            component
            icon
            name
            spotOrder
            hostOrder
        }
        peopleAmenities {
            component
            icon
            name
            spotOrder
            hostOrder
        }
        venueAmenities {
            component
            icon
            name
            spotOrder
            hostOrder
        }
    }
`;

export const F_FAVOTITE_SPOT_INFO = gql`
    fragment FavoriteSpotInfo on Spot {
        ...SpotInfo
    }
    ${SPOT_INFO}
`;

export const SPOT_MEMBERSHIP_HOST_FRAGMENT = gql`
    fragment spotMembershipHostFragment on Spot {
        id
        title
        thumbPhoto
        top
        offerMembership
        offerSniffpass
        activeMembership
        maximumMembers
        memberHostBenefits
        memberPrice2Hour
        memberPrice4Hour
        memberPrice8Hour
        memberReservationDelayTime
        members {
            id
            nameAndInitial
            avatarAws
            membershipDogs
            membershipHours
            membershipAmount
            membershipMonthlyPrice
        }
    }
`;
