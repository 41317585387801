import React from 'react';
import { TOP_CITIES } from '../../consts/top-cities';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import { RouteFormatter } from '../../routes';
import { cityLink, toColumns } from '../../helpers/column-view';
import { appleStoreLink, facebookLink, fmdLink, googlePlayLinkUrl, instagramLink, ppgLink, tiktokLink, youtubeLink } from '../../consts/link.consts';
import { PARK_TYPES } from '../../consts/type-park.consts';
import sniffspotAppImg from '../../assets/img/sniffspot-app-ss.png';
import TiktokWidget from '../tiktok-widget';
import SnifSection from '../snif-section';
import PictureSet from '../picture-set';
import gPlayImg from '../../assets/img/google-play.png';
import fmdImg from '../../assets/img/fmd.png';
import ppgImg from '../../assets/img/ppg.png';
import Rating from '../rating';
import ytImg from '../../assets/img/youtube-circle.png';
import ttImg from '../../assets/img/tiktok-circle.png';
import fbImg from '../../assets/img/fb-circle.png';
import igImg from '../../assets/img/ig-circle.png';
import SvgAppleStore from 'src/assets/svg/SvgAppleStore.svg';
import SvgSnifLogo from 'src/assets/svg/SvgSnifLogo.svg';
import './style.scss';

export const FOLLOW_US = [
    {
        alt: 'Tiktok',
        img: ttImg,
        link: tiktokLink,
    },
    {
        alt: 'Instagram',
        img: igImg,
        link: instagramLink,
    },
    {
        alt: 'Facebook',
        img: fbImg,
        link: facebookLink,
    },
    {
        alt: 'Youtube',
        img: ytImg,
        link: youtubeLink,
    },
];

const cookiePolicyUrl = 'https://app.termly.io/document/cookie-policy/a835591d-c7a8-420a-a18c-5fb94b2d5be2';

const FooterNew = ({ className, isHome, tiktokBanner = true, SSR = false, isMobileSSR, useState, isShowCities }) => {
    const isMobile = SSR ? isMobileSSR : useMobileScreen();

    return (
        <footer className={`footer ${className}`}>
            {tiktokBanner && (
                <SnifSection>
                    <TiktokWidget {...{ SSR, useState }} />
                </SnifSection>
            )}
            <SnifSection>
                <div className="row">
                    <div className="col-12 d-block">
                        <div className="logo">
                            <a href={RouteFormatter.home()}>
                                <SvgSnifLogo />
                            </a>
                        </div>
                        <div className="snif-p mt-2 mb-md-2">© Sniffspot, Inc.</div>
                    </div>
                    <div className="col-12 col-md-4 mt-4 mt-md-2">
                        <div className="snif-s1 snif-semibold mb-2">Company</div>
                        <div className="snif-p">
                            <div className="col-12">
                                <div className="mb-2">
                                    <a href={RouteFormatter.web.about()}>About us</a>
                                </div>
                                <div className="mb-2">
                                    <a href={RouteFormatter.web.trust()}>Trust &amp; safety</a>
                                </div>
                                <div className="mb-2">
                                    <a href={RouteFormatter.web.terms()}>Terms of service</a>
                                </div>
                                <div className="mb-2">
                                    <a href={RouteFormatter.web.privacy()}>Privacy policy</a>
                                </div>
                                <div
                                    className="mb-2"
                                    dangerouslySetInnerHTML={{
                                        __html: "<a href='#' rel='nofollow' id='termly-consent-preferences' onclick='window.displayPreferenceModal();return false;'>Cookie preferences</a>",
                                    }}
                                />
                                <div className="mb-1">
                                    <a href={RouteFormatter.helpCenter.root()}>Help Center</a>
                                </div>
                                <div className="mb-2">
                                    <a href="mailto:support@sniffspot.com" className="snif-s2 snif-medium text-primary">
                                        support@sniffspot.com
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 snif-p mt-2">
                        <div className="snif-s1 snif-semibold mb-2">Navigation</div>
                        <div className="mb-2">
                            <a href={RouteFormatter.home()}>Home</a>
                        </div>
                        <div className="mb-2">
                            <a href={RouteFormatter.listings({})}>Explore spots</a>
                        </div>
                        <div className="mb-2">
                            <a href={RouteFormatter.hostLanding()}>Become a host</a>
                        </div>
                        <div className="mb-2">
                            <a href={RouteFormatter.customerReviews()}>Customer reviews</a>
                        </div>
                        <div className="mb-2">
                            <a href={RouteFormatter.blog()}>Blog</a>
                        </div>
                        <div className="mb-2">
                            <a href={RouteFormatter.app()}>Mobile app</a>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mt-4 mt-md-2">
                        <div className="snif-s1 snif-semibold mb-2">Browse by park types</div>
                        {PARK_TYPES.map((link, i) => (
                            <a key={i} href={link.url}>
                                <p className="snif-p snif-regular mb-2">{link.text}</p>
                            </a>
                        ))}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-4 mt-4 mt-md-2">
                        <div className="snif-s1 snif-semibold mb-2">Follow us</div>
                        <p className="snif-s2 text-deep mb-3">Find Sniffspot on your favorite social media</p>
                        <div className="d-flex">
                            {FOLLOW_US.map((v, i) => (
                                <a key={i} href={v.link} target="_blank" rel="noopener noreferrer">
                                    <PictureSet className="me-2" imgs={v.img} alt={v.alt} width={48} height={48} />
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mt-4 mt-md-2">
                        <div className="snif-s1 snif-semibold mb-2">Visit our partners</div>
                        <div className="mb-4">
                            <a href={fmdLink} target="_blank" rel="noopener noreferrer">
                                <PictureSet imgs={fmdImg} width={188.65} height={40} alt="FMD Partner" />
                            </a>
                        </div>
                        <div className="mb-2">
                            <a href={ppgLink} target="_blank" rel="noopener noreferrer">
                                <PictureSet imgs={ppgImg} width={103.2} height={80} alt="PPG Partner" />
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mt-4 mt-md-2 d-flex">
                        <div className="content-app-left">
                            <div className="snif-s1 snif-semibold mb-1">Download Sniffspot iOS and Android App</div>
                            <div className="d-flex align-items-center mb-3">
                                <Rating className="app-stars" showNmb={false} value={5} />
                                <p className="snif-s2 snif-regurlar text-medium">4.9 • 7K Ratings</p>
                            </div>
                            <div className="app-row">
                                <div>
                                    <a href={appleStoreLink} target="_blank" rel="noopener noreferrer">
                                        <SvgAppleStore />
                                    </a>
                                </div>
                                <div>
                                    <a href={googlePlayLinkUrl} target="_blank" rel="noopener noreferrer">
                                        <PictureSet imgs={gPlayImg} alt="Google Play Link" width={189} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="content-app-right">
                            <PictureSet imgs={sniffspotAppImg} alt="Sniffspot App Screenshot" width={108} height={219} />
                        </div>
                    </div>
                </div>
                {(isShowCities || !isHome) && (
                    <div className="mt-3 mb-3">
                        <div className="snif-m1 snif-semibold mb-2">Browse by top cities</div>
                        <div className="d-flex justify-content-between medium-gray-color">
                            {toColumns(TOP_CITIES, isMobile ? 2 : 5).map((col, i) => (
                                <div key={i}>{col.map((c, j) => cityLink(c.city, c.url, j, SSR))}</div>
                            ))}
                        </div>
                    </div>
                )}
                <div className="snif-s1 mt-4">
                    We use essential cookies to make our site work. We may also use non-essential cookies to improve user experience, personalize
                    content, customize advertisements, and analyze website traffic. For these reasons, we may share your site usage data with our
                    social media, advertising, and analytics partners. By using our website, you agree to our website's cookie use as described in our{' '}
                    <a href={cookiePolicyUrl} target="_blank" rel="noopener noreferrer nofollow" className="snif-medium text-underline">
                        Cookie Policy
                    </a>
                    . You can change your cookie settings at any time by clicking “
                    <span
                        dangerouslySetInnerHTML={{
                            __html: "<a href='#' rel='nofollow' id='termly-consent-preferences' class='snif-semibold text-primary text-underline' onclick='window.displayPreferenceModal();return false;'>Preferences.</a>",
                        }}
                    />
                    ”
                </div>
            </SnifSection>
        </footer>
    );
};

export default FooterNew;
