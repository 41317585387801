import bp1 from '../../assets/img/bp-1.jpg?sizes[]=128';
import bp2 from '../../assets/img/bp-2.jpg?sizes[]=128';
import bp3 from '../../assets/img/bp-3.jpg?sizes[]=128';
import bp4 from '../../assets/img/bp-4.jpg?sizes[]=128';
import bp5 from '../../assets/img/bp-5.jpg?sizes[]=128';
import bp6 from '../../assets/img/bp-6.jpg?sizes[]=128';
import bp7 from '../../assets/img/bp-7.png?sizes[]=128';
import bp8 from '../../assets/img/bp-8.png?sizes[]=128';
import bp9 from '../../assets/img/bp-9.png?sizes[]=128';
import { ImageHelper } from '../../classes/image-helper.class';
import { IMG_RESIZES } from '../../consts/resizer.consts';
import { RouteFormatter } from '../../routes';

const today = new Date();

export const mainMenu = [
    {
        id: 'DP',
        name: 'Our dog parks',
        url: RouteFormatter.listings({}),
    },
    {
        id: 'BG',
        name: 'Blog',
        url: RouteFormatter.blog(),
    },
    {
        id: 'DN',
        name: 'Dog names',
        url: RouteFormatter.dogNames(),
    },
    {
        id: 'DR',
        name: 'Top dog rescues',
        url: RouteFormatter.dogRescue(),
    },
    {
        id: 'TR',
        name: 'Top dog trainers',
        url: RouteFormatter.blogTrainersUS(),
    },
    {
        id: 'DB',
        name: 'Dog breeds',
        url: RouteFormatter.blogDogBreeds(),
    },
    {
        name: 'Become a host',
        url: RouteFormatter.hostLanding(),
    },
];

export const topCities = [
    {
        name: 'Austin',
        url: 'austin-tx',
    },
    {
        name: 'Chicago',
        url: 'chicago-il',
    },
    {
        name: 'Denver',
        url: 'denver-co',
    },
    {
        name: 'Houston',
        url: 'houston-tx',
    },
    {
        name: 'San Diego',
        url: 'san-diego-ca',
    },
    {
        name: 'Seattle',
        url: 'seattle-wa',
    },
];

export const blogs = [
    {
        title: 'A Comprehensive Guide to Dog Exercise',
        img: ImageHelper.getUrlFromOptions(bp1, `full-fit-in/${IMG_RESIZES.x128}`),
        url: 'https://www.sniffspot.com/blog/dog-enrichment/a-comprehensive-guide-to-dog-exercise',
    },
    {
        title: 'How To Crate train An Older Dog - A Comprehensive Guide',
        img: ImageHelper.getUrlFromOptions(bp2, `full-fit-in/${IMG_RESIZES.x128}`),
        url: 'https://www.sniffspot.com/blog/dog-training/crate-train-an-older-dog',
    },
    {
        title: 'How To Socialize A Dog - A Comprehensive Guide',
        img: ImageHelper.getUrlFromOptions(bp3, `full-fit-in/${IMG_RESIZES.x128}`),
        url: 'https://www.sniffspot.com/blog/dog-training/how-to-socialize-a-dog',
    },
    {
        title: 'Leash Training: A Guide to Training Your Dog to Walk on a Leash',
        img: ImageHelper.getUrlFromOptions(bp4, `full-fit-in/${IMG_RESIZES.x128}`),
        url: 'https://www.sniffspot.com/blog/dog-training/leash-training-a-guide-to-training-your-dog-to-walk-on-a-leash',
    },
    {
        title: 'Skijoring: A Guide to Skijoring with Dogs',
        img: ImageHelper.getUrlFromOptions(bp5, `full-fit-in/${IMG_RESIZES.x128}`),
        url: 'https://www.sniffspot.com/blog/dog-enrichment/skijoring-a-guide-to-skijoring-with-dogs',
    },
    {
        title: 'The Best Dog Enrichment Toys',
        img: ImageHelper.getUrlFromOptions(bp6, `full-fit-in/${IMG_RESIZES.x128}`),
        url: 'https://www.sniffspot.com/blog/dog-enrichment/the-best-dog-enrichment-toys',
    },
    {
        title: 'The Best United States Dog Parks',
        img: ImageHelper.getUrlFromOptions(bp7, `full-fit-in/${IMG_RESIZES.x128}`),
        url: 'https://www.sniffspot.com/blog/city-dog-parks/the-best-united-states-dog-parks',
    },
    {
        title: 'The Best Indoor Dog Parks in the United States',
        img: ImageHelper.getUrlFromOptions(bp8, `full-fit-in/${IMG_RESIZES.x128}`),
        url: 'https://www.sniffspot.com/blog/city-dog-parks/the-best-indoor-dog-parks-in-the-united-states',
    },
    {
        title: 'The Best Off-Leash Dog Parks in the United States',
        img: ImageHelper.getUrlFromOptions(bp9, `full-fit-in/${IMG_RESIZES.x128}`),
        url: 'https://www.sniffspot.com/blog/city-dog-parks/the-best-off-leash-dog-parks-in-the-united-states',
    },
];

export const trainers = [
    {
        title: 'Top dog trainers in Denver',
        url: 'https://www.sniffspot.com/blog/dog-trainers/denver-co',
    },
    {
        title: 'Top dog trainers in Atlanta',
        url: 'https://www.sniffspot.com/blog/dog-trainers/atlanta-ga',
    },
    {
        title: 'Top dog trainers in Chicago',
        url: 'https://www.sniffspot.com/blog/dog-trainers/chicago-il',
    },
    {
        title: 'Top dog trainers in Nevada',
        url: 'https://www.sniffspot.com/blog/dog-trainers/nevada',
    },
    {
        title: 'Top dog trainers in Seattle',
        url: 'https://www.sniffspot.com/blog/dog-trainers/seattle-wa',
    },
    {
        title: 'Top dog trainers in Nashville',
        url: 'https://www.sniffspot.com/blog/dog-trainers/nashville-tn',
    },
    {
        title: 'More dog trainers...',
        url: 'https://www.sniffspot.com/blog/dog-trainers/united-states',
    },
];

export const topBlogs = [
    {
        title: 'Dog Training',
        url: 'https://www.sniffspot.com/blog/dog-training',
    },
    {
        title: 'Dog Reactivity',
        url: 'https://www.sniffspot.com/blog/dog-reactivity',
    },
    {
        title: 'Dog Enrichment',
        url: 'https://www.sniffspot.com/blog/dog-enrichment',
    },
    {
        title: 'Sniffspot Community',
        url: 'https://www.sniffspot.com/blog/sniffspot-community',
    },
    {
        title: 'City Dog Parks',
        url: 'https://www.sniffspot.com/blog/city-dog-parks',
    },
];

export const topDogNames = [
    { title: 'Most Popular Male Dog Names', url: 'https://www.sniffspot.com/dog-names/male' },
    { title: 'Most Popular Female Dog Names', url: 'https://www.sniffspot.com/dog-names/female' },
    { title: 'Most Popular Golden Retriever Dog Names', url: 'https://www.sniffspot.com/dog-names/golden-retriever' },
    { title: 'Most Popular Labrador Retriever Dog Names', url: 'https://www.sniffspot.com/dog-names/labrador-retriever' },
    { title: 'Most Popular Siberian Husky Dog Names', url: 'https://www.sniffspot.com/dog-names/siberian-husky' },
    { title: 'Most Popular German Shepherd Dog Names', url: 'https://www.sniffspot.com/dog-names/german-shepherd-dog' },
];

export const topDogRescues = [
    { title: `Best Washington Dog Rescues & Shelters in ${today.getFullYear()}`, url: 'https://www.sniffspot.com/dog-rescues/washington' },
    { title: `Best Oregon Dog Rescues & Shelters in ${today.getFullYear()}`, url: 'https://www.sniffspot.com/dog-rescues/oregon' },
    { title: `Best California Dog Rescues & Shelters in ${today.getFullYear()}`, url: 'https://www.sniffspot.com/dog-rescues/california' },
    { title: `Best Florida Dog Rescues & Shelters in ${today.getFullYear()}`, url: 'https://www.sniffspot.com/dog-rescues/florida' },
    { title: `Best New York Dog Rescues & Shelters in ${today.getFullYear()}`, url: 'https://www.sniffspot.com/dog-rescues/new-york' },
    { title: `Best Texas Dog Rescues & Shelters in ${today.getFullYear()}`, url: 'https://www.sniffspot.com/dog-rescues/texas' },
];

export const dogBreeds = [
    {
        title: 'Explore the German Shepherd Dog: Genuine Tips from Owners',
        url: 'https://www.sniffspot.com/blog/dog-breeds/what-to-expect-from-german-shepherd-dog-real-insights-from-dog-owners',
    },
    {
        title: 'Explore the Labrador Retriever: Real Tips from Owners',
        url: 'https://www.sniffspot.com/blog/dog-breeds/explore-the-labrador-retriever-real-tips-from-owners',
    },
    {
        title: 'Explore the Golden Retriever: Genuine Tips from 9,000+ Owners',
        url: 'https://www.sniffspot.com/blog/dog-breeds/explore-the-golden-retriever-genuine-tips-from-9000-owners',
    },
    {
        title: 'Explore the American Staffordshire Terrier: Genuine Tips from 9,000+ Owners',
        url: 'https://www.sniffspot.com/blog/dog-breeds/explore-the-american-staffordshire-terrier-genuine-tips-from-9000-owners',
    },
    {
        title: 'Explore the Australian Shepherd: Genuine Tips from 9,000+ Owners',
        url: 'https://www.sniffspot.com/blog/dog-breeds/explore-the-australian-shepherd-genuine-tips-from-9000-owners',
    },
];
